<template>
    <h1></h1>Category works!
</template>

<script>
export default {
    
}
</script>

<style scoped>

</style>